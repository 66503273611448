<template>
    <div class="icon-with-text--container">
        <img class="icon" :src="icon" alt=""/>
        <span class="text" v-if="text">{{ text }}</span>
        <span v-else class="text">
            <template v-if="data.length">
                <span v-if="data.length > 3">
                    <el-tooltip
                        :content="data.join(', ')"
                        effect="dark"
                        placement="top"
                    >
                      <span>{{ data.slice(0, 3).join(', ') + '...' }}</span>
                    </el-tooltip>
                </span>
                <span v-else>{{ data.join(', ') }}</span>
            </template>
            <span v-else>-</span>
        </span>
    </div>
</template>

<script>

export default {
    name: "IconWithText",

    props: {
        icon: String,
        text: String,
        data: Array
    },
};
</script>
<style lang="scss" scoped>
.icon-with-text--container {
    display: flex;
    align-items: center;

    .icon {
        width: 14px;
        height: auto;
        max-height: 15px;
        margin-right: 5px;
    }

    .text {
        font-weight: 400;
        font-size: 14px;
        color: #8B8B8B;
        opacity: 0.87;
    }
}
</style>
