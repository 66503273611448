<template>
  <div v-if="showItem" class="product-areas-item-wrapper">
    <div v-if="loading" class="product-areas-item__loading">
      <CustomLoader />
    </div>
    <div v-else class="d-flex justify-content-between flex-wrap product-areas-item">
      <div class="product-areas-item__left">
        <el-select v-model="productAreas" placeholder="Select" size="large" @change="handleChangeSelect">
          <el-option
              v-for="item in availableProductAreas"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-select>

        <div class="mt-5">
          <CircleChart
            v-if="item.score"
            :chartData="item.score"
            :chartConfigs="{
              width: 375,
              height: 0.9 * 320,
              valueVisible: false
            }"
            :values="[item.score.score, item.score.importance]"
          />
        </div>

        <div class="product-areas-item__score" :class="{'isLoadingDetails': isLoadingDetails}">
          <span>{{ item.scoreCount }} {{ $t('Scores') }}</span>
          <a href="" @click.prevent="productAreaOverview">
              <div class="product-areas-loader">
                  <div class="spinner-border text-primary" role="status"></div>
              </div>
              {{ $t('View Details') }}
          </a>
        </div>
      </div>
      <div class="product-areas-item__right">
        <div class="analysis-card">
          <div class="analysis-card__title">
            <span>{{ $t('GAP ANALYSIS') }}</span>
            <el-select
              v-model="analysisValue"
              :disabled="!item.questionList.length"
              placeholder="Select"
              size="large"
              class="sort-select"
            >
              <el-option
                  v-for="item in analysisOptions"
                  :key="item"
                  :label="item.label"
                  :value="item.key"
              />
            </el-select>
          </div>

          <div class="analysis-card__carousel">
            <ProductAreasSliderItem
                :id="null"
                v-if="!item.questionList.length"
                :title="$t('No Questions yet')"
                :text="$t('You can see relevant questions here once you receive feedback')"
                :score="getEmptyBarChartValue.score.value"
                :importance="getEmptyBarChartValue.importance.value"
                :gap="getEmptyBarChartValue.gap.value"
                :positive="getEmptyBarChartValue.positive"
            />

            <CustomLoader v-else-if="isSorting"/>

            <carousel v-else :mouseDrag="false">
              <slide
                  v-for="slide in item.questionList"
                  :key="slide"
              >
                <ProductAreasSliderItem
                    :id="slide.id"
                    :title="$t('Question')"
                    :text="slide.question"
                    :score="slide.score"
                    :importance="slide.importance"
                    :gap="slide.gap"
                    :chartData="slide.chartData"
                    :positive="true"
                    :commentsCount="slide.commentsCount"
                />
              </slide>

              <template #addons>
                <div class="analysis-card__footer">
                  <navigation />
                </div>
              </template>
            </carousel>
          </div>
        </div>
<!--        <div class="counter-card">-->
<!--          <div class="counter-card__item">-->
<!--            <div class="counter-card__item&#45;&#45;title" v-if="!item.followed.score">-->
<!--              0 <span>{{ $t('Followed') }}</span>-->
<!--            </div>-->
<!--            <div class="counter-card__item&#45;&#45;title" v-else>-->
<!--              {{ $t('Followed') }}-->
<!--            </div>-->
<!--            <div class="" v-if="!item.followed.score">-->
<!--              <router-link to="/bank/customers/all">{{ $t('Follow Customers') }}</router-link>-->
<!--            </div>-->
<!--            <div class="d-flex" v-else>-->
<!--              <div class="label-status" :class="getStatusClass(item.followed.score)">-->
<!--                {{ item.followed.score ? item.followed.score : '0.00' }}-->
<!--              </div>-->
<!--              <div class="label-status">-->
<!--                {{ item.followed.value ? item.followed.value : '0.00' }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="counter-card__item" :class="{'empty-state': !item.high.score}">-->
<!--            <div class="counter-card__item&#45;&#45;title">-->
<!--              {{ $t('High') }}-->
<!--            </div>-->
<!--            <div class="d-flex">-->
<!--              <div class="label-status" :class="getStatusClass(item.high.score)">-->
<!--                {{ item.high.score ? item.high.score : '0.00' }}-->
<!--              </div>-->
<!--              <div class="label-status">-->
<!--                {{ item.high.value ? item.high.value : '0.00' }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="counter-card__item" :class="{'empty-state': !item.low.score}">-->
<!--            <div class="counter-card__item&#45;&#45;title">-->
<!--              {{ $t('Low') }}-->
<!--            </div>-->
<!--            <div class="d-flex">-->
<!--              <div class="label-status" :class="getStatusClass(item.low.score)">-->
<!--                {{ item.low.score ? item.low.score : '0.00' }}-->
<!--              </div>-->
<!--              <div class="label-status">-->
<!--                {{ item.low.value ? item.low.value : '0.00' }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!-- COMING SOON START -->
        <div class="counter-card is-coming-soon">
          <div class="counter-card__item">
            <div class="counter-card__item--title">{{ $t('Followed') }}</div>
            <div class="counter-card__item--value">{{ $t('Coming Soon') }}</div>
          </div>
          <div class="counter-card__item">
            <div class="counter-card__item--title">{{ $t('High') }}</div>
            <div class="counter-card__item--value">{{ $t('Coming Soon') }}</div>
          </div>
          <div class="counter-card__item">
            <div class="counter-card__item--title">{{ $t('Low') }}</div>
            <div class="counter-card__item--value">{{ $t('Coming Soon') }}</div>
          </div>
        </div>
        <!-- COMING SOON END-->
      </div>
    </div>
    <div class="product-areas-item__close" v-if="index > 0" @click="removeItem()">
      <inline-svg src="/media/buying/icons/close-red.svg" />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {scoresSortingLevels} from "@/buying-teams/pages/bank/dashboard/dashboardData";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import {getStatusClass} from "@/core/helpers/GlobalHelper";
import BarChartModel from "@/store/models/BarChartModel";
import CustomBarChart from "@/buying-teams/shared-components/charts/CustomBarChart";
import ProductAreasSliderItem from "@/buying-teams/pages/bank/dashboard/components/ProductAreasSliderItem";
import {CompareProductAreaModel} from "@/store/models/bank/CompareProductAreaModel";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CircleChart from "@/buying-teams/shared-components/charts/CircleChart";
import { Position } from "@element-plus/icons-vue";

export default {
  name: "ProductAreasItem",
  components: {
      Position,
    CircleChart,
    CustomLoader,
    ProductAreasSliderItem,
    CustomBarChart,
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    emptyState: {
      type: Boolean,
      default: false
    },
    productAreaItem: String,
    index: Number,
    customerId: Number,
    availableProductAreas: Array,
    showFiltered: Boolean
  },
  watch: {
    async analysisValue(val) {
      this.isSorting = true;
      await this.item.sortQuestionsBy(val);
      this.isSorting = false;
    },
  },
  data() {
    return {
      item: new CompareProductAreaModel(null),
      getStatusClass,
      productAreas: '',
      analysisValue: '',
      loading: false,
      showItem: true,
      isSorting: false,
      isLoadingDetails: false
    }
  },
  created() {
    this.productAreas = this.productAreaItem
    this.analysisValue = this.item.analysisValue
    if (this.productAreaItem) {
      this.getBankCompareProductAreas(this.productAreaItem)
    }
  },
  computed: {
    analysisOptions() {
      return scoresSortingLevels;
    },
    getEmptyBarChartValue() {
      return new BarChartModel({
        score: 0,
        importance: 0,
        gap: 0
      })
    },
    bankOverviewDashboardFilter() {
      let filters = store.getters.bankOverviewDashboardFilter;
      return {
        time_period: filters.time_period,
        tiers: filters.tiers,
        customers_hq: filters.customers_hq,
        industries: filters.industries,
        countries: filters.countries
      }
    },
  },
  methods: {
    getBankCompareProductAreas(productArea) {
      this.loading = true;
      const filter = this.showFiltered ? this.bankOverviewDashboardFilter : (this.customerId ? {
        businesses: [this.customerId]
      } : null);
      store.dispatch('fetchBankCompareProductAreas', {
        productArea,
        customerId: this.customerId,
        filter
      })
        .then(res => {
          this.analysisValue = 'highest_score';
          this.item = new CompareProductAreaModel(res);
          this.item.sortQuestionsBy(this.analysisValue);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    handleChangeSelect() {
      this.getBankCompareProductAreas(this.productAreas)
    },
    removeItem() {
      this.showItem = false;
    },
    productAreaOverview() {
      this.isLoadingDetails = true;
      this.$emit('onClickProductAreas', this.productAreas);
    },
  }
}
</script>

<style lang="scss">
.product-areas-item {
  padding-bottom: 24px;
  margin-bottom: 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &-wrapper {
    position: relative;
    &:last-child {
      .product-areas-item {
        border-bottom: none;
      }
    }
  }

  &__left {
    width: 380px;
    .el-select {
      .el-input {
        &__inner {
          height: 50px;
          border-radius: 50px;
          background: #fff;
          border: 1px solid #D1D1D1;
          padding-left: 23px;
          font-weight: 700;
          font-size: 18px;
          line-height: 13px;
          color: rgba(0, 0, 0, 0.87);
        }
        &__suffix {
          right: 18px;
        }
      }
    }
  }
  &__score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      background: rgba(246, 246, 246, 0.5);
      border-radius: 4px;
      padding: 10px 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
      color: rgba(109, 113, 122, 0.5);
    }

      a {
          position: relative;
          .product-areas-loader {
              position: absolute;
              top: 50%;
              left: -17px;
              transform: translateY(-50%);
              display: none;
              .spinner-border {
                  --bs-spinner-width: 1rem;
                  --bs-spinner-height: 1rem;
                  --bs-spinner-border-width: 0.05em;
              }
          }
      }

      &.isLoadingDetails {
          a {
              cursor: default;
              .product-areas-loader {
                  display: block;
              }
          }
      }
  }
  &__right {
    padding-left: 34px;
    width: calc(100% - 380px);
  }
  .analysis-card {
    padding: 14px 24px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    &__title {
      display: flex;
      align-items: center;
      > span {
        font-weight: 700;
        font-size: 16px;
        line-height: 158.02%;
        color: #2B2B2B;
        margin-right: 24px;
      }
      .el-select {
        width: 219px;

        .el-input {
          &__inner {
            height: 35px;
            padding-left: 20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #313131;
          }
        }
      }
    }
    &__carousel {
      margin-top: 10px;
      margin-left: -10px;
      margin-right: -10px;
      h1 {
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: rgba(113, 113, 113, 0.5);
        text-align: left;
      }
    }
    &__item {
      width: 100%;
      position: relative;

      &--count {
        position: absolute;
        top: -18px;
        right: 0;
        background: #F8F8F8;
        border-radius: 6px;
        padding: 8px 15px;
        img {
          margin-right: 9px;
        }
        span {
          font-weight: 700;
          font-size: 12px;
          line-height: 13px;
          color: #000000;
          opacity: 0.87;
        }
      }

      &--question {
        margin-top: 7px;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        line-height: 158.02%;
        color: #2B2B2B;
      }
      &--chart {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 24px;
      }
      &--labels {
        display: flex;
        .analysis-label {
          margin-right: 20px;
          h4 {
            font-weight: 700;
            font-size: 14px;
            line-height: 13px;
            color: #000000B2;
          }
          span {
            font-weight: 400;
            font-size: 12.905px;
            line-height: 16px;
            text-align: center;
            color: #0000007F;
            padding-left: 16px;
            position: relative;
            &:after {
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
          &--score:after {
            background: #435BF4;
          }
          &--importance:after {
            background: #CED4F8;
          }
          &--gap {
            &:after {
              background: #CA0F0F;
            }
            &.gap-positive:after {
              background: #1DC28B;
            }
          }

          &.empty-state {
            span:after {
              filter: grayscale(100%);
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      .carousel {
        &__prev,
        &__next {
          position: revert;
          transform: revert;
          background: transparent;
          width: 32px;
          height: 24px;
          background-image: url("/media/buying/icons/arrow.png");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          filter: invert(31%) sepia(28%) saturate(6745%) hue-rotate(226deg) brightness(97%) contrast(96%);
          svg {
            display: none;
          }
          top: 0;

          &--in-active {
            filter: invert(69%) sepia(0%) saturate(1843%) hue-rotate(142deg) brightness(90%) contrast(87%);
          }
        }
        &__prev {
          left: 0;
          margin-right: 32px;
        }
        &__next {
          right: 0;
          transform: rotate(180deg);
        }
        &__pagination {
          padding: 0 33px;
          margin: 0;
        }
      }
    }
  }
  .counter-card {
    display: flex;
    gap: 12px;
    margin-top: 31px;
    &__item {
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 17px;
      padding: 23px 24px;
      width: 33.33%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--title {
        font-weight: 700;
        font-size: 16px;
        line-height: 13px;
        color: #515151;
        span {
          font-weight: 400;
        }
      }
      .label-status {
        width: max-content;
        border-radius: 2px;
        font-weight: 400;
        font-size: 14.2326px;
        line-height: 17px;
        padding: 5px 10px;
        color: #515151;
        &.status-danger {
          font-weight: 800;
          background: #FDF0F0;
          color: #ED7B74;
        }
        &.status-warning {
          font-weight: 800;
          background: #FEF9E7;
          color: #E6B25F;
        }
        &.status-success {
          font-weight: 800;
          background: #e8fee1;
          color: #63B775;
        }
      }
      &.empty-state {
        .label-status {
          &.status-danger {
            background: #F9F9F9;
            color: #B4B6BB;
          }
          &.status-warning {
            background: #F9F9F9;
            color: #B4B6BB;
          }
          &.status-success {
            background: #F9F9F9;
            color: #B4B6BB;
          }
        }
      }
    }
    &.is-coming-soon {
      .counter-card__item--title {
        color: #717171;
      }
      .counter-card__item--value {
        font-style: italic;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: rgba(113, 113, 113, 0.6);
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 1200px) {
  .product-areas-item {
    .product-areas-item__left {
      width: 100%;
    }
    .product-areas-item__right {
      width: 100%;
      padding-left: 0;
      margin-top: 25px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1350px) {
  .product-areas-item .counter-card {
    flex-direction: column;
  }
  .product-areas-item .counter-card__item {
    width: 100%;
  }
}

@media (min-width: 530px) and (max-width: 769px) {
  .product-areas-item {
    .counter-card__item {
      padding: 15px 20px;
      flex-direction: column;
      &--title {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 530px) {
  .product-areas-item .counter-card {
    flex-direction: column;
    &__item {
      width: 100%;
    }
  }
}
@media (max-width: 400px) {
  .bank-dashboard__info .score-section__left {
    overflow: hidden;
    .ring {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
