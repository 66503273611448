<template>
  <router-link :to="link" class="request-idea-item" :key="key">
    <div class="request-idea-item__header">
      <div class="request-idea-item__header--image">
        <img :src="image" :alt="name" width="13" height="13">
      </div>
      <div class="request-idea-item__header--name">
        {{ name }}
      </div>
      <div class="request-idea-item__header--date">
        {{ moment(date).fromNow() }}
      </div>
    </div>
    <div class="request-idea-item__body">
      {{ text ? text : '...' }}
    </div>
  </router-link>
</template>

<script>
import moment from "moment"

export default {
  name: "RequestIdeaItem",
  props: {
    key: {
      type: Number,
      default: 0
    },
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      moment
    }
  }
}
</script>

<style scoped lang="scss">
.request-idea-item {
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;

  > div {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: -7px;
    left: -7px;
    bottom: 12px;
    right: -7px;
    background: rgba(72, 143, 249, 0.05);
    border-radius: 10px;
    z-index: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
  }

  &:hover:after {
    opacity: 1;
    visibility: visible;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &__header {
    display: flex;
    align-items: center;
    &--image {
      margin-right: 6px;
      img {
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
      }
    }
    &--name {
      font-weight: 500;
      font-size: 12px;
      line-height: 158.02%;
      color: #717171;
      padding-right: 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 10px;
    }
    &--date {
      font-weight: 400;
      font-size: 12px;
      line-height: 158.02%;
      color: #969696;
    }
  }
  &__body {
    font-weight: 400;
    font-size: 16px;
    line-height: 158.02%;
    color: #2B2B2B;
    padding-top: 10px;
    padding-bottom: 17px;
  }
}
</style>
