import {QuestionModel} from "@/store/models/bank/CompareProductAreaModel";
import {Constants} from "@/core/config/constants";
import {BaseModel} from "@/store/models/base/BaseModel";
import {BankUsersContainer} from "@/store/models/bank/BankUsersContainer";
import {
    CustomQuestionsAnalyticsContainer
} from "@/store/models/shared/custom-questions-analytics/CustomQuestionsAnalyticsContainer";

export class BankCustomerDataContainer extends BaseModel {
    allQuestions: QuestionModel[];
    business_hq: string;
    business_name: string;
    countries_count: string;
    icon_path: string;
    ideas: any[];
    industries: string[];
    is_following: boolean;
    last_feedback: string | null;
    product_area_rate: number;
    product_areas: string[];
    requests: object;
    stats: object;
    tier: string;
    users_count: string;
    user_scores: BankUsersContainer;
    chartData: any;
    customQuestionsSectionData: CustomQuestionsAnalyticsContainer = new CustomQuestionsAnalyticsContainer();

    constructor(obj) {
        super(obj);

        this.allQuestions = obj.all_questions ? obj.all_questions.map(q => new QuestionModel(q)) : [];
        this.business_hq = obj.business_hq ? obj.business_hq : '-';
        this.business_name = obj.business_name ? obj.business_name : '';
        this.countries_count = obj.countries_count ? obj.countries_count : '0';
        this.icon_path = obj.icon_path ? obj.icon_path : Constants.BUSINESS_TMP_LOGO;
        this.ideas = obj.ideas ? obj.ideas : [];
        this.industries = obj.industries ? obj.industries : [];
        this.is_following = obj.is_following ? obj.is_following : false;
        this.last_feedback = obj.last_feedback ? obj.last_feedback : null;
        this.product_area_rate = obj.product_area_rate ? obj.product_area_rate : 0;
        this.product_areas = obj.product_areas ? obj.product_areas : [];
        this.requests = obj.requests ? obj.requests : {};
        this.stats = obj.stats ? obj.stats : {score: 0, importance: 0, scores: 0};
        this.tier = obj.tier ? obj.tier : '';
        this.users_count = obj.users_count ? obj.users_count : '0';
        this.user_scores = new BankUsersContainer(obj.user_scores);
        this.chartData = obj.dotChart;

        if (obj.custom_questions) {
            this.customQuestionsSectionData.setCustomQuestionsAnalyticsData(obj.custom_questions);
        }
        this.sortQuestionsBy('lowest_score');
    }

    async sortQuestionsBy(what: string | null): Promise<QuestionModel[]> {
        switch (what) {
            case 'highest_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.score) - parseFloat(q1.score));
                break;
            case 'lowest_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case 'highest_importance_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.importance) - parseFloat(q1.importance));
                break;
            case 'lowest_importance_score':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.importance) - parseFloat(q2.importance));
                break;
            case 'highest_negative_gap':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.gap) - parseFloat(q2.gap));
                break;
            case 'highest_positive_gap':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q2.gap) - parseFloat(q1.gap));
                break;
            case 'highest_standard_deviation':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
            case 'lowest_standard_deviation':
                this.allQuestions = this.allQuestions.sort((q1: QuestionModel, q2: QuestionModel) => parseFloat(q1.score) - parseFloat(q2.score));
                break;
        }
        return this.allQuestions;
    }
}
