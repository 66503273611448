import { UserModelMin } from "@/store/models/shared/UserModelMin";
import { ScoreModel } from "@/store/models/shared/ScoreModel";

export class BankUsersContainer {
  usersList:  BankUserContainerItem[];

  constructor(obj) {
    this.usersList = obj ? obj.map(cms => new BankUserContainerItem(cms)) : [];
  }

  async sortCustomersBy(what: string | null): Promise<BankUserContainerItem[]> {
    switch (what) {
      case 'highest_score':
        this.usersList = this.usersList.sort((s1: BankUserContainerItem, s2: BankUserContainerItem) => parseFloat(s2.scores.score) - parseFloat(s1.scores.score));
        break;
      case 'lowest_score':
        this.usersList = this.usersList.sort((s1: BankUserContainerItem, s2: BankUserContainerItem) => parseFloat(s1.scores.score) - parseFloat(s2.scores.score));
        break;
      case 'highest_importance_score':
        this.usersList = this.usersList.sort((s1: BankUserContainerItem, s2: BankUserContainerItem) => parseFloat(s2.scores.importance) - parseFloat(s1.scores.importance));
        break;
      case 'lowest_importance_score':
        this.usersList = this.usersList.sort((s1: BankUserContainerItem, s2: BankUserContainerItem) => parseFloat(s1.scores.importance) - parseFloat(s2.scores.importance));
        break;
      case 'highest_negative_gap':
        this.usersList = this.usersList.sort((s1: BankUserContainerItem, s2: BankUserContainerItem) => parseFloat(s1.scores.gap) - parseFloat(s2.scores.gap));
        break;
      case 'highest_positive_gap':
        this.usersList = this.usersList.sort((s1: BankUserContainerItem, s2: BankUserContainerItem) => parseFloat(s2.scores.gap) - parseFloat(s1.scores.gap));
        break;
    }
    return this.usersList;
  }
}

class BankUserContainerItem {
  user: UserModelMin;
  scores: ScoreModel;

  constructor(obj: any) {
    this.user = new UserModelMin(obj);
    this.scores = new ScoreModel(obj);
  }
}
