<template>
  <div class="bank-dashboard__requests dashboard-sections mb-9">
    <div class="dashboard-section__title d-flex justify-content-between align-items-center pe-3">
      <span>
        {{ $t('Requests') }}
        <span class="dashboard-section__title--count">{{ requestCount }}</span>
      </span>
      <router-link to="/bank/feedback/requests">{{ $t('View All Requests') }}</router-link>
    </div>
    <div class="card requests-card">
      <el-tabs v-if="requestData.length">
        <el-tab-pane v-for="(item, index) in requestData" :key="index">
          <template #label>
            <div class="custom-tabs-label" :class="'tab-' + item.id">
              <span class="custom-tabs-label__name">{{ item.tabName }}</span>
              <span class="custom-tabs-label__count">{{ item.tabData.length }}</span>
            </div>
          </template>

          <div class="tab-data-list pt-5">
            <div v-if="item.tabData.length">
              <div v-if="viewScreen === 'bankOverview'">
                <RequestIdeaItem
                  v-for="(list, listIdx) in item.tabData"
                  :key="listIdx"
                  :image="list.business_icon_path ? list.business_icon_path : Constants.BUSINESS_TMP_LOGO"
                  :name="`${list.business_name}`"
                  :text="list.description"
                  :date="list.created_at"
                  :link="`/bank/feedback/request/${list.id}`"
                />
              </div>
              <div v-else>
                <RequestIdeaItem
                  v-for="(list, listIdx) in item.tabData"
                  :key="listIdx"
                  :image="list.icon_path ? list.icon_path : Constants.USER_TMP_LOGO"
                  :name="`${list.first_name} ${list.last_name}`"
                  :text="list.description"
                  :date="list.created_at"
                  :link="`/bank/feedback/request/${list.id}`"
                />
              </div>
            </div>

            <div class="empty-list-state pt-20" v-else>
              <div class="empty-list-state__title mb-3">
                {{ $t('No Requests yet') }}
              </div>
              <div class="empty-list-state__subtitle mb-5">
                {{ $t('There are no registered requests yet.') }}
              </div>
              <img src="/media/buying/no-requests-yet.svg" alt="">
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
        <div class="empty-list-state pt-20" v-else>
            <div class="empty-list-state__title mb-3">
                {{ $t('No Requests yet') }}
            </div>
            <div class="empty-list-state__subtitle mb-5">
                {{ $t('There are no registered requests yet.') }}
            </div>
            <img src="/media/buying/no-requests-yet.svg" alt="">
        </div>
    </div>
  </div>
</template>

<script>
import RequestIdeaItem from "@/buying-teams/pages/bank/dashboard/components/RequestIdeaItem";
import {Constants} from "@/core/config/constants";

export default {
  name: "RequestsSection",
  components: {RequestIdeaItem},
  props: {
    requests: {
      type: Object,
      default: {}
    },
    viewScreen: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      Constants
    }
  },
  computed: {
    requestCount() {
      let count = 0;
      this.requestData.map((val => {
        count += val.tabData.length
      }))
      return count
    },
    requestData() {
      const data = []
      for (let [key, item] of Object.entries(this.requests)) {
        data.push({
          id: key,
          tabName: this.$t(key),
          tabData: item
        })
      }
      return data.reverse()
    }
  }
}
</script>

<style lang="scss">
.requests-card {
  padding: 23px 32px;
  .el-tabs__item {
    height: 32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    &.is-active,
    &:hover {
      color: #000000;
    }
  }
  .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    .custom-tabs-label {
      padding-left: 5px;
    }
  }
  .el-tabs__content {
    overflow: revert;
  }
  .custom-tabs-label {
    padding-bottom: 14px;
    &__name {
      padding-left: 15px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
    &__count {
      background: #ECECEC;
      border-radius: 3px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      padding: 2px 5px;
      margin-left: 8px;
    }

    &.tab-critical .custom-tabs-label__name:after {
      background: #D12323;
      animation: pulse-red 2s infinite;
    }
    &.tab-high .custom-tabs-label__name:after {
      background: #DE6B00;
    }
    &.tab-normal .custom-tabs-label__name:after {
      background: #0C5FDC;
    }
    &.tab-low .custom-tabs-label__name:after {
      background: #28A57F;
    }
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    box-shadow: 0 0 0 6px rgba(255, 82, 82, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@media (min-width: 1200px) {
  .requests-card {
    min-height: 434px;
  }
}
</style>
