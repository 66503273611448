<template>
    <div class="dashboard-common-filters" id="dedicatedCustomerFilter">
        <div class="dashboard-common-filters__top">
            <div class="dashboard-common-filters__top--title">
                <span>{{ $t("Filters") }}</span>
                <a v-if="showFilterBadges" @click.prevent="setFiltersDefaultState" href="">{{ $t("Clear All") }}</a>
            </div>

            <FiltersScoreImportanceInfo />
        </div>

        <div class="section-card">
            <div class="dashboard-common-filters__card">
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Time Period") }}</div>

                    <DateRangePicker
                        @onTouch="isFilterTouched = true"
                        v-model="filterData.time_period"
                        :all-time-selection-value="getBankDefaultTimePeriod"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Product Areas") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.product_areas"
                        multiple
                        show-badge
                        self-value
                        :options="filterAllData.all_product_areas"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.PRODUCT_AREAS))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.PRODUCT_AREAS))"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Country of the Bank") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.countries"
                        multiple
                        show-badge
                        self-value
                        :options="filterAllData.all_countries"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.COUNTRIES))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.PRODUCT_AREAS))"
                        :placeholder="$t('Select Countries')"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item">
                    <div class="dashboard-common-filters__item--label">{{ $t("Feedback Sessions") }}</div>

                    <SelectBoxWithModal
                        v-model="filterData.survey_ids"
                        multiple
                        show-badge
                        :options="filterAllData.all_survey_ids"
                        :all-selected-text="$t(filterAllSelectedText(BankFiltersSelectTypeEnum.FEEDBACK_SESSIONS))"
                        :custom-selection-text="$t(filterCustomSelectionText(BankFiltersSelectTypeEnum.FEEDBACK_SESSIONS))"
                        :labelType="SELECT_BOX_TYPES.FEEDBACK_SESSIONS"
                        @onTouch="isFilterTouched = true"
                    />
                </div>
                <div class="dashboard-common-filters__item apply-section">
                    <button @click="handleApplyFilters" class="main-btn btn apply-section__btn">
                        {{ $t("Apply") }}

                        <span
                            v-show="isFilterTouched && !isFilterApplied"
                            class="apply-section__btn--badge"
                        >
                        <BusinessFilterAppliedBadges
                            :filterData="filterData"
                            :allData="filterAllData"
                            :forceShow="showFilterBadges === 0"
                            @getSize="handleGetSize"
                        />
                    </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import FiltersScoreImportanceInfo from "@/buying-teams/shared-components/utils/FiltersScoreImportanceInfo";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";
import {SELECT_BOX_TYPES} from "@/core/config/constants";
import BusinessFilterAppliedBadges from "@/buying-teams/shared-components/filter/BusinessFilterAppliedBadges";
import DateRangePicker from "@/buying-teams/shared-components/date-time/DateRangePicker";
import { filterAllSelectedText, filterCustomSelectionText } from "@/core/helpers/FiltersHelper";
import { BankFiltersSelectTypeEnum } from "@/store/enums/bank/BankFiltersSelectTypeEnum";

export default {
    name: "DedicatedCustomerFilter",
    components: {
        DateRangePicker,
        BusinessFilterAppliedBadges,
        SelectBoxWithModal,
        FiltersScoreImportanceInfo
    },
    data() {
        return {
            isFilterTouched: false,
            showFilterBadges: false,
            isFilterApplied: true,
            SELECT_BOX_TYPES,
            filterData: {
                time_period: null,
                product_areas: [],
                countries: [],
                survey_ids: [],
            },
            filterAllSelectedText,
            filterCustomSelectionText,
            BankFiltersSelectTypeEnum,
        }
    },
    computed: {
        getBankDefaultTimePeriod() {
            return store.getters.getBankDefaultTimePeriod;
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
        singleCustomerFilter() {
            return store.getters.getBankFiltersState.singleCustomer;
        },
        filterAllData() {
            return {
                all_time_period: this.singleCustomerFilter.time_period,
                all_product_areas: this.singleCustomerFilter.product_areas,
                all_countries: this.singleCustomerFilter.countries,
                all_survey_ids: this.singleCustomerFilter.survey_ids,
            };
        },
        bankSingleCustomersFilterData() {
            return store.getters.getBankSingleCustomersFilterData;
        },
    },
    created() {
        this.setFilterData(this.bankSingleCustomersFilterData);
    },
    methods: {
        setFiltersDefaultState() {
            this.isFilterApplied = false;
            this.isFilterTouched = false;
            this.filterData = {
                time_period: this.filterAllData.all_time_period,
                product_areas: this.filterAllData.all_product_areas,
                countries: this.filterAllData.all_countries,
                survey_ids: this.filterAllData.all_survey_ids.map(c => c.id),
            };
            this.$emit('applyFilters', this.filterData);
        },
        handleApplyFilters() {
            this.isFilterApplied = true;
            this.$emit('applyFilters', this.filterData);
        },
        setFilterData(newFilters) {
            for (let [key, value] of Object.entries(newFilters)) {
                this.filterData[key] = value;
            }
        },
        handleGetSize(touchedCount) {
            this.isFilterApplied = false;
            this.showFilterBadges = touchedCount;
        }
    }
}
</script>

<style lang="scss">
#dedicatedCustomerFilter {
    width: 100%;
    .section-card {
        padding: 14px 14px 8px 10px;
    }
    .dashboard-common-filters__card {
        padding: 0;
    }
    .dashboard-common-filters__item {
        .el-select, .el-input, .el-input__inner {
            width: 100%;
        }
    }
    .apply-section__btn {
        margin-top: 0;
    }
}
</style>
