<template>
    <div class="customer-item platform-dashboard-pages">
        <template v-if="!filterLoading">
            <a class="back-to-btn mb-0" href="" @click.prevent="handleBackPrevPage">
                <img src="/media/buying/icons/arrow-left.svg" alt="">
                {{ $t("Back to [Previous Screen]", { previousScreen: 'Previous Screen' }) }}
            </a>

            <el-select
              v-model="customerId"
              class="dedicated-field-state mt-0"
              filterable
              :disabled="loading"
              @change="handleCustomerChange"
            >
                <el-option
                  v-for="(item, index) in getBankCustomersList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
            </el-select>

            <DedicatedCustomerFilter
              ref="dedicatedCustomerFilter"
              @apply-filters="applyCustomerFilters"
            />
        </template>

        <CustomLoader v-if="loading" height="50vh"/>

        <div class="w-100" v-else-if="customerContainer">
            <CustomerItemInfo :customer="customerContainer"/>

            <div class="row custom-row">
                <div class="col-lg-10">
                    <div class="w-100 mt-9 mb-9">
                        <div class="dashboard-section__title">
                            {{ $t('Average Score over time (All Product Areas)') }}
                        </div>
                        <div class="col card development-over-time">
                            <LineChart :chartData="customerContainer.chartData"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    <div class="w-100 mt-9 mb-9">
                        <div class="dashboard-section__title">
                            {{ $t('Company Stats') }}
                        </div>
                        <div class="col card development-over-time d-flex flex-column justify-content-center">
                            <div class="d-flex justify-content-center">
                                <CustomCount
                                    :data="{count: customerContainer.users_count, icon: '/media/buying/icons/users-icon.svg', title: $t('Active Users')}"/>
                            </div>
                            <div class="d-flex justify-content-center mt-9">
                                <CustomCount
                                    :data="{count: customerContainer.countries_count, icon: '/media/buying/icons/map-icon-2.svg', title: $t('Countries')}"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row custom-row">
                <div class="col-lg-7">
                    <div class="row custom-row">
                        <div class="col-12">
                            <RequestsSection :requests="customerContainer.requests"/>
                        </div>
                        <div class="col-12">
                            <IdeasSection :ideas="customerContainer.ideas"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <UserScores :usersContainer="customerContainer.user_scores"/>
                </div>
            </div>

            <QuestionsTabsContainer
                v-if="customerContainer"
                :is-loading="loading"
                :benchmark-questions-list="customerContainer.allQuestions"
                :custom-questions="customerContainer.customQuestionsSectionData"
                @onClickQuestionItem="handleClickQuestionItem"
                @onSortingChange="customerContainer.sortQuestionsBy($event)"
            />
        </div>
    </div>
</template>

<script>
import CustomerItemInfo from "@/buying-teams/pages/bank/customers/sections/CustomerItemInfo";
import CompareProductAreas from "@/buying-teams/pages/bank/dashboard/sections/CompareProductAreas";
import RequestsSection from "@/buying-teams/pages/bank/dashboard/sections/RequestsSection";
import IdeasSection from "@/buying-teams/pages/bank/dashboard/sections/IdeasSection";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {BankCustomerDataContainer} from "@/store/models/bank/BankCustomerDataContainer";
import LineChart from "@/buying-teams/shared-components/charts/LineChart";
import {diagLog} from "@/core/helpers/GlobalHelper";
import UserScores from "@/buying-teams/pages/bank/dashboard/sections/UserScores";
import CustomCount from "@/buying-teams/pages/bank/dashboard/components/CustomCount";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";
import DedicatedCustomerFilter from "@/buying-teams/pages/bank/customers/components/DedicatedCustomerFilter";

export default {
    name: "DedicatedCustomerPage",
    components: {
        DedicatedCustomerFilter,
        QuestionsTabsContainer,
        CustomCount,
        UserScores,
        CustomLoader,
        IdeasSection,
        RequestsSection,
        CompareProductAreas,
        CustomerItemInfo,
        LineChart
    },
    data() {
        return {
            loading: true,
            filterLoading: true,
            customerId: null,
            customerContainer: null
        }
    },
    computed: {
        bank() {
            return store.getters.bank;
        },
        userInfo() {
            return store.getters.currentUser
        },
        bankSingleCustomersFilterData() {
            return store.getters.getBankSingleCustomersFilterData;
        },
        getBankCustomersList() {
            return store.getters.getBankCustomersList;
        }
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });
    },
    async mounted() {
        this.customerId = this.$route.params.id || null;
        if (this.customerId && this.customerId === '*') {
            let customerId = this.getBankCustomersList[0] ? this.getBankCustomersList[0].id : null;
            if (!customerId) {
                await redirectToRouteWithName("bank-customers");
            }
            this.customerId = Number(customerId);
            this.handleCustomerChange(this.customerId);
            return;
        } else {
            this.customerId = Number(this.customerId);
        }
        if (!this.customerId) {
            await redirectToRouteWithName("bank-customers");
        }

        this.initPageData();
    },
    methods: {
        async initPageData() {
            await store.dispatch('initFiltersData', {
                type: BankFiltersTypeEnum.SINGLE_CUSTOMER,
                payload: {business_id: this.customerId},
                needSave: false
            });
            this.filterLoading = false;

            this.fetchCustomerData(this.bankSingleCustomersFilterData);
        },
        handleBackPrevPage() {
            this.$router.go(-1);
        },
        async fetchCustomerData(filter) {
            this.loading = true;
            store.dispatch('fetchBankCustomerDetails', {filter, business_id: this.customerId})
                .then(res => {
                    this.customerContainer = new BankCustomerDataContainer(res.data);
                    diagLog('CUSTOMER => ', {res, customerContainer: this.customerContainer})
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        applyCustomerFilters(filters) {
            this.fetchCustomerData(filters)
        },
        async handleClickQuestionItem(question, newTab) {
            this.customerContainer.customQuestionsSectionData.redirectLoading = question.id;

            const filters = await store.dispatch('singleQuestionRedirectFilterState', {
                questionId: question.id,
                filterData: {
                    ...this.$refs.dedicatedCustomerFilter.filterData,
                    customers: [this.customerId]
                },
            });

            store.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', filters);
            this.customerContainer.customQuestionsSectionData.redirectLoading = null;

            const questionUrl = `/bank/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        },
        handleCustomerChange(id) {
            this.$router.replace({ name: "bank-customer", params: { id } });
            this.loading = true;
            this.filterLoading = true;
            this.initPageData();
        }
    },
    watch: {
        "$route.params": {
            handler() {
                const params = this.$route.params.id;
                if (params && params === '*' && this.customerId) {
                    this.$router.replace({ name: "bank-customer", params: { id: this.customerId } });
                }
            }
        }
    },
};
</script>

<style lang="scss">
.customer-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    .country-select {
        margin-top: 10px;

        .el-input__inner {
            padding-left: 10px;
            height: 33px;
            border-bottom: 2px solid #363636;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 13px;
            color: rgba(0, 0, 0, 0.87);
            width: 276px;
        }

        .el-input__suffix svg path {
            fill: #000000;
        }
    }

    .custom-row {
        --bs-gutter-x: 2rem;
    }

    @media (min-width: 992px) {
        .development-over-time {
            min-height: 344px;
        }
    }
}
</style>
