<template>
  <div class="bank-dashboard__customer-scores dashboard-sections mb-9">
    <div class="dashboard-section__title d-flex justify-content-between align-items-center pe-3">
      <span>
        {{ $t('Company Users') }}
        <span class="dashboard-section__title--count">{{ usersContainer.usersList ? usersContainer.usersList.length : 0 }}</span>
      </span>
    </div>
    <div class="card customer-scores-card">
      <div class="customer-scores-card__header">
        <h1>{{ $t('Sort Users By') }}:</h1>
        <el-select v-model="usersSorting" placeholder="Select" size="large" class="sort-select">
          <el-option
            v-for="(item, index) in usersOptions"
            :key="index"
            :label="$t(item.label)"
            :value="item.key"
          />
        </el-select>
      </div>
      <div class="customer-scores-card__body">

        <CustomLoader v-if="usersContainer && isSorting"/>
        <div v-else>
          <UsersList :users="usersContainer.usersList" v-if="usersContainer.usersList && usersContainer.usersList.length" />
          <div v-else class="empty-list-state pt-20">
            <div class="empty-list-state__title">
              {{ $t('No Users') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomersList from "@/buying-teams/pages/bank/dashboard/components/CustomersList";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import {scoresSortingLevels} from "@/buying-teams/pages/bank/dashboard/dashboardData";
import UsersList from "@/buying-teams/shared-components/settings/UsersList";
export default {
  name: "UserScores",
  components: { UsersList, CustomLoader, CustomersList},
  props: {
    usersContainer: {
      type: Object
    }
  },
  watch: {
    usersSorting: {
      async handler(newVal) {
        this.isSorting = true;
        await this.usersContainer.sortCustomersBy(newVal);
        this.isSorting = false;
      }
    }
  },
  data() {
    return {
      usersSorting: 'highest_score',
      isSorting: false
    }
  },
  computed: {
    usersOptions() {
      return scoresSortingLevels;
    }
  },
  created() {
    if (this.usersContainer) {
      this.usersContainer.sortCustomersBy(this.usersSorting);
    }
  }
}
</script>

<style lang="scss">
.bank-dashboard__customer-scores {
  height: 100%;
}
.customer-scores-card {
  padding: 22px 0 0 0;
  height: 94%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 20px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    h1 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .el-select {
      width: 190px;

      .el-input {
        &__inner {
          height: 35px;
          padding-left: 20px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #313131;
        }
        &__suffix {
          right: 15px;
        }
      }
    }
  }
}
</style>
