<template>
  <div class="users-list">
    <div class="users-list__item" v-for="(item, index) in users" :key="index">
      <div class="d-flex align-items-center">
        <div class="users-list__item--img">
          <img :src="item.user.icon_path" width="24" height="24" alt="">
        </div>
        <div class="users-list__item--name">
          {{ item.user.getFullName() }}
        </div>
      </div>

      <div class="users-list__item--values">
        <el-tooltip
          popper-class="custom-bar-chart__tooltip"
          :content="$t('Score')"
          :show-arrow="true"
          placement="top"
          effect="light"
        >
          <div
            class="user-value with-bg"
            :class="{
              [getStatusClass(item.scores.score)]: item.scores.score,
              'no-data': !item.scores.score
            }"
          >
            {{ item.scores.score ? numberDecimalsFormat(item.scores.score) : '--' }}
          </div>
        </el-tooltip>
        <el-tooltip
          popper-class="custom-bar-chart__tooltip"
          :content="$t('Importance')"
          :show-arrow="true"
          placement="top"
          effect="light"
        >
          <div
            class="user-value"
            :class="{'no-data': !item.scores.importance}"
          >
            {{ item.scores.importance ? numberDecimalsFormat(item.scores.importance) : '--' }}
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>

import { getStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
  name: "UsersList",
  components: {},
  props: {
    widgetClasses: String,
    users: Array
  },
  data() {
    return {
      getStatusClass,
      numberDecimalsFormat
    }
  }
};
</script>
<style scoped lang="scss">
.users-list {
  height: 100%;
  max-height: 780px;
  overflow-y: auto;

  .users-list__item--name {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 100px;
    background: #D9D9D9;
    border: 4px solid #fff;
  }

  &__item {
    padding: 18px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &:last-child {
      border-bottom: none;
    }

    &--img {
      margin-right: 17px;

      img {
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
      }
    }

    &--name {
      font-weight: 500;
      font-size: 16px;
      line-height: 158.02%;
      color: #2B2B2B;

    }

    &--values {
      display: flex;
      align-items: center;

      .user-value {
        font-weight: 600;
        font-size: 16px;
        line-height: 158.02%;
        margin-right: 24px;
        padding: 5px 10px;
        min-width: 56px;
        text-align: center;
        border-radius: 5px;
        &.with-bg {
          font-weight: 800;
          &.status-danger {
            background: #FDF0F0;
            color: #ED7B74;
          }
          &.status-warning {
            background: #FEF9E7;
            color: #E6B25F;
          }
          &.status-success {
            background: #e8fee1;
            color: #0A882D;
          }
          &.no-data {
            background: #f6f6f6;
          }
        }
      }

      .user-percentage {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #CE2419;

        svg {
          fill: #E22D21;
          transform: rotate(180deg);
        }

        &.green-type {
          color: #1B9F72;

          svg {
            fill: #2BC490;
            transform: rotate(0);
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    &__item {
      padding: 14px 15px;
    }
  }
}
.follow-status {
  width: max-content;
  border-radius: 2px;
  font-weight: 400;
  font-size: 14.2326px;
  line-height: 17px;
  padding: 5px 10px;
  color: #515151;
  margin: 0 auto;
  &.status-danger {
    font-weight: 800;
    background: #FDF0F0;
    color: #ED7B74;
  }
  &.status-warning {
    font-weight: 800;
    background: #FEF9E7;
    color: #E6B25F;
  }
  &.status-success {
    font-weight: 800;
    background: #e8fee1;
    color: #0A882D;
  }
}
</style>

