<template>
  <div class="customer-info card">
    <div class="customer-info__row">
      <img class="customer-info--logo" :src="customer.icon_path ? customer.icon_path : Constants.BUSINESS_TMP_LOGO" />
      <div class="customer-content">
        <div class="customer-info--data">
          <span class="name">{{ customer.business_name }}</span>
          <div class="d-flex flex-wrap gap-7 mt-3 mb-3">
            <IconWithText :icon="'/media/buying/icons/general/gen064.svg'" :text="$t('Tier num', { num: customer.tier })" />
            <IconWithText :icon="'/media/buying/icons/general/fin011.svg'" :data="customer.industries" />
            <IconWithText :icon="'/media/buying/icons/map-icon.svg'" :data="[customer.business_hq]" />
          </div>
          <div class="counters">
            <div class="counters-col">
              <div class="counter">
              <span class="text">
                {{ $t("Average Score") }}
              </span>
                <span :class="`count ${customer.stats.score ? getStatusClass(+customer.stats.score) : ''}`">
                {{ numberDecimalsFormat(customer.stats.score) }}
              </span>
              </div>
              <div class="counter">
              <span class="text">
                {{ $t("Average Importance") }}
              </span>
                <span class="count isGray">
                {{ numberDecimalsFormat(customer.stats.importance) }}
              </span>
              </div>
            </div>
            <div class="divider"></div>
            <div class="counters-col">

              <div class="counter">
              <span class="text">
                {{ $t("Product Areas Rated") }}
              </span>
                <span class="count">
                {{ customer.product_area_rate }}
              </span>
              </div>
              <div class="counter">
              <span class="text">
                {{ $t("Last Feedback Date") }}
              </span>
                <span class="count isSmall">
                {{ customer.last_feedback ? moment(customer.last_feedback).format('DD MMM YYYY') : $t('No Data') }}
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <div class="customer-info--button-wrapper">
          <div class="follow-button">
<!--            <FollowingButton type="outline" :status="followed" @click="followed = !followed" :title="buttonTitle" />-->
          </div>
          <div class="custom-count-container">
<!--            <CustomCount :data="feedbackReplies" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FollowingButton from "@/buying-teams/shared-components/utils/FollowingButton";
import { getStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import IconWithText from "@/buying-teams/pages/bank/customers/components/IconWithText";
import CustomCount from "@/buying-teams/pages/bank/dashboard/components/CustomCount";
import moment from "moment";
import {Constants} from "@/core/config/constants";

export default {
  name: "CustomerItemInfo",
  props: {
    customer: Object
  },
  data() {
    return {
      getStatusClass,
      numberDecimalsFormat,
      Constants,
      moment,
      followed: false,
      buttonTitle: "Follow Company",
      feedbackReplies: {
        count: 0,
        title: "Feedback Replies",
        icon: "/media/buying/icons/share-user.svg"
      }
    };
  },
  components: { CustomCount, IconWithText, FollowingButton },
  created() {
    this.followed = this.customer.is_following;
    this.feedbackReplies.count = this.customer.stats.scores
  }
};
</script>

<style lang="scss" scoped>

.customer-info {
  padding: 21px;
  margin-top: 26px;

  .button-container {
    width: 200px;
  }
  .customer-content {
    width: calc(100% - 359px);
  }
  &__row {
    display: flex;
  }
  &--logo {
    width: 159px;
    height: 159px;
    max-height: 159px;
    object-fit: cover;
    margin-right: 24px;
  }

  &--data {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name {
      font-weight: 700;
      font-size: 24px;
      color: #000000;
    }

    .counters {
      display: flex;
      gap: 12px;

      .divider {
        width: 1px;
        background: rgba(0,0,0,0.1);
        margin: 0 6px;
      }

      .counters-col {
        display: flex;
        gap: 12px;

        .counter {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #E5E5E5;
          border-radius: 8px;
          padding: 10px 12px;
          min-width: 139px;

          .count {
            font-weight: 800;
            font-size: 24px;
            color: #000000;

            &.isGray {
              color: #515151;
              opacity: 0.87;
            }

            &.isSmall {
              font-size: 18px;
            }

            &.status-danger {
              color: #ED7B74;
            }

            &.status-warning {
              color: #E6B25F;
            }

            &.status-success {
              color: #63B775;
            }
          }

          .text {
            font-weight: 400;
            font-size: 14px;
            color: #9A9A9A;
            opacity: 0.87;
          }

          .divider {
            width: 1px;
            background-color: #000000;
            opacity: 0.1;
            margin: 0 18px;
          }
        }
      }
    }
  }

  &--button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .follow-button {
      height: 38px;
      width: 100%;
      max-width: 235px;
    }

    .custom-count-container {
      width: 100%;
      height: 93px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .row &--logo, .row .button-container {
    padding: 0;
  }
  @media (max-width: 1439px) {
    .customer-info--data {
      padding-right: 15px;
      .counters {
        flex-direction: column;

        .divider {
          display: none;
        }

        .counter {
          width: 50%;
        }
      }
    }
    .customer-info--button-wrapper {
      justify-content: flex-end;
    }
  }
  @media (max-width: 1375px) {
    .customer-content {
      margin: 0 auto;
      min-width: fit-content;
    }
  }
  @media (min-width: 500px) and (max-width: 1200px) {
    .button-container {
      width: 100%;
      flex-direction: row;
      .customer-info--button-wrapper {
        flex-direction: row;
        justify-content: space-between;
        .custom-count-container {
          width: max-content;
        }
        .custom-count {
          flex-direction: row-reverse;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .customer-content {
      width: calc(100% - 183px);
    }

    .customer-info__row {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 600px) {
    .customer-info__row {
      flex-direction: column;
      align-items: center;
    }
    .customer-content {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .customer-info--button-wrapper {
      flex-direction: column-reverse;
      .custom-count-container {
        margin-bottom: 15px;
      }
    }
    .counters-col {
      flex-direction: column;
    }
    .customer-info--data .counters .counter {
      width: 100%;
    }
  }
}


</style>
