
import { defineComponent, PropType } from "vue";
import { numberDecimalsFormat } from "@/core/helpers/GlobalHelper";
import CustomBarChart from "@/buying-teams/shared-components/charts/CustomBarChart.vue";
import BarChartModel from "@/store/models/BarChartModel";

export default defineComponent({
  name: "ProductAreasSliderItem",
  components: {CustomBarChart},
  props: {
    id: Number,
    text: {
      type: String,
      default: ''
    },
    commentsCount: [String, Number],
    title: String,
    score: [String, Number],
    importance: [String, Number],
    gap: [String, Number],
    positive: Boolean,
    chartData: {
      type: Object as PropType<BarChartModel>,
      default: () => new BarChartModel({score: 0, importance: 0, gap: 0})
    }
  },
  data() {
    return {
      numberDecimalsFormat
    }
  },
  methods: {
    redirectQuestionPage() {
      if (!this.id) return;
      this.$router.push(`/bank/questions/${this.id}`)
    }
  }
})
