
export class ScoreModel {
  score: string;
  importance: string;
  gap: string;

  constructor(obj: any) {
    this.score = obj.score;
    this.importance = obj.importance;
    this.gap = obj.gap ? obj.gap : obj.score - obj.importance;
  }
}
