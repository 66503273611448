<template>
  <div class="bank-dashboard__ideas dashboard-sections mb-9">
    <div class="dashboard-section__title d-flex justify-content-between align-items-center pe-3">
      <span>
        {{ $t('Ideas') }}
        <span class="dashboard-section__title--count">{{ ideas.length }}</span>
      </span>
      <router-link to="/bank/feedback/ideas">{{ $t('View All Ideas') }}</router-link>
    </div>
    <div class="card idea-card">
      <div v-if="ideas.length">
        <div v-if="viewScreen === 'bankOverview'">
          <RequestIdeaItem
            v-for="(item, itemIdx) in ideas"
            :key="itemIdx"
            :image="item.business_icon_path ? item.business_icon_path : Constants.BUSINESS_TMP_LOGO"
            :name="`${item.business_name}`"
            :text="item.description"
            :date="item.created_at"
            :link="`/bank/feedback/idea/${item.id}`"
          />
        </div>
        <div v-else>
          <RequestIdeaItem
            v-for="(item, itemIdx) in ideas"
            :key="itemIdx"
            :image="item.icon_path ? item.icon_path : Constants.USER_TMP_LOGO"
            :name="`${item.first_name} ${item.last_name}`"
            :text="item.description"
            :date="item.created_at"
            :link="`/bank/feedback/idea/${item.id}`"
          />
        </div>
      </div>
      <div class="empty-list-state pt-20" v-else>
        <div class="empty-list-state__title mb-3">
          {{ $t('No Ideas yet') }}
        </div>
        <div class="empty-list-state__subtitle mb-5">
          {{ $t('There are no registered ideas yet.') }}
        </div>
        <img src="/media/buying/no-ideas-yet.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import RequestIdeaItem from "@/buying-teams/pages/bank/dashboard/components/RequestIdeaItem";
import {Constants} from "@/core/config/constants";

export default {
  name: "IdeasSection",
  components: {RequestIdeaItem},
  props: {
    ideas: {
      type: Array,
      default: () => []
    },
    viewScreen: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      Constants
    }
  }
}
</script>

<style scoped lang="scss">
.idea-card {
  padding: 23px 32px;
}
@media (min-width: 1200px) {
  .idea-card {
    min-height: 371px;
  }
}
</style>
