<template>
  <div class="bank-dashboard__compare dashboard-section mb-9 position-relative">
    <div v-if="showHeader" class="dashboard-section__title">
      {{ $t('Compare Product Areas') }}
    </div>

    <div class="card">
      <ProductAreasItem
        v-if="productAreasSections.length"
        v-for="(item, index) in productAreasSections"
        :key="index"
        :productAreaItem="item"
        :index="index"
        :customerId="customerId"
        :showFiltered="showFiltered"
        :availableProductAreas="productAreas"
        @onClickProductAreas="$emit('onClickProductAreas', $event)"
      />

      <ProductAreasItem v-else :emptyState="true" />
    </div>

    <button class="btn w-100 py-5 btn-light-primary menage-btn" :disabled="productAreas.length < 2" @click="addNewSection">
      + {{ $t('Add Product Area to Compare') }}
    </button>
  </div>
</template>

<script>
import ProductAreasItem from "@/buying-teams/pages/bank/dashboard/components/ProductAreasItem";
import store from "@/store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";

export default {
  name: "CompareProductAreas",
  components: {CustomLoader, ProductAreasItem},
  props:{
    showHeader: Boolean,
    customValue: String,
    customerId: Number,
    productAreas: Array,
    showFiltered: Boolean
  },
  data() {
    return {
      loading: true,
      productAreasSections: ['']
    }
  },
  computed: {
    bankDashboardData() {
      return store.getters.bankDashboardData
    }
  },
  methods: {
    addNewSection() {
      this.productAreasSections.push('')
    }
  },
  created() {
    if (this.customValue) {
      this.productAreasSections = [this.customValue];
    } else if (this.productAreas.length) {
      this.productAreasSections = [this.productAreas[0]];
    }
  }
}
</script>

<style lang="scss">
.bank-dashboard, .customer-item {
  &__compare {
    .card {
      padding: 32px 16px;
    }
    .menage-btn {
      padding-top: 22px !important;
      padding-bottom: 22px !important;
    }
  }

  .product-areas-item {
    &__close {
      position: absolute;
      top: -12px;
      right: -8px;
      background: #FFDFDD;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &__loading {
      height: 390px;
    }
  }
}
</style>
